/* **************************************************************************
 *
 *  WARNING: DO NOT RENAME -> ENUMS MUST BE KEPT IN SYNC WITH POSTHOG
 *
 * ***************************************************************************/

export enum PostHogEventsEnum {
    CompanionEnergyMetersEditAttachmentUploaded = 'CompanionEnergyMetersEditAttachmentUploaded',
    CompanionEnergyMetersEditManual = 'CompanionEnergyMetersEditManual',
    CompanionEnergyMetersConfirmed = 'CompanionEnergyMetersConfirmed',
    CompanionToggleWaterAndEnergy = 'Companion Toggle Water And Energy',
    CompanionSearchTransaction = 'Companion Search Transaction',
    CompanionConfirmWaterMeterReadings = 'Companion Confirm Water Meter Readings',
    CompanionDeletePicture = 'Companion Delete Picture',
    CompanionClickListViewTransactionIcon = 'Companion Click List View Transaction Icon',
    CompanionClickListViewTransactionDetails = 'Companion Click List View Transaction Details',

    DesktopEnergyMetersEditAttachmentUploaded = 'DesktopEnergyMetersEditAttachmentUploaded',
    DesktopEnergyMetersEditManual = 'DesktopEnergyMetersEditManual',
    DesktopEnergyMetersConfirmed = 'DesktopEnergyMetersConfirmed',

    /** Water Transfer */
    SaveWaterHandling = 'Save Water Handling',
    UpdateTransferDate = 'Update Transfer Date',
    SaveOnlyWaterInfo = 'Save Only Water Info',
    SaveAndConfirmWaterInfo = 'Save and Confirm Water Info',
    ConfirmOnlyWaterInfo = 'Confirm Only Water Info',

    /** Services **/
    ClickService = 'Click Service',
    ViewLineup = 'View Line-up',
    ClickChooseOffer = 'Click Choose Offer',
    ClickChangeOffer = 'Click Change Offer',
    ClickNewSimulation = 'Click New Simulation',
    ClickNextOrderInfo = 'Click Next Order Info',
    ClickNextFulfillment = 'Click Next Fulfillment',
    ClickChangeFulfillment = 'Click Change Fulfillment',
    ClickNextTransactionDetails = 'Click Next Transaction Details',
    ClickNextContactDetails = 'Click Next Contact Details',
    ConfirmOffer = 'Confirm Offer',
    ClickUpsellService = 'Click Upsell Service',
    ClickDone = 'Click Done',
    ViewContactDetails = 'View Contact Details',
    ViewTransactionDetails = 'View Transaction Details',

    /** Meter readings **/
    ViewMeterReadings = 'View Meter Readings',

    /** Contact us **/
    ClickContactUs = 'Click Contact Us',

    /** Leaver Dashboard */
    ViewChangeDateModal = 'View Change Date Modal',
    ClickGeneralHelp = 'Click General Help',
    ViewDashboard = 'View Dashboard',
    EditMeterReadings = 'Edit Meter Readings',
    SaveMeterReadings = 'Save Meter Readings',
    ClickMeterReadings = 'Click Meter Readings', // Not available yet
    SaveEnergySuppliers = 'Save Energy Suppliers',
    ClickAddSuppliers = 'Click Add Suppliers',
    ClickEnergyFinalInvoice = 'Click Energy Final Invoice',
    ClickEnergySuppliers = 'Click Energy Suppliers', // Not available yet
    ClickDedicatedHelp = 'Click Dedicated Help',
    SaveServiceHandling = 'Save Service Handling',
    ClickOtherSmoovedServices = 'Click Other Smooved Services'
}
